import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GoogleDriveProviderService {
  url ='https://spreadsheets.google.com/feeds/list/1E46DNTdgtxD3tgr-w145g-L6epajYUUhSb1L5MOPbak/ob2dxe1/public/full?alt=json'; // 'https://spreadsheets.google.com/feeds/list/1GGQ72gG2yiAWn7JzylC9X7ZXfQth89K5sTfKCk0Chas/ocp6odj/public/full?alt=json'
  equityurl = 'https://spreadsheets.google.com/feeds/list/13F_WjQTH6gDq7zeGihkQL3GVeadi8-IJYtgk9Medus4/1/public/full?alt=json';
  constructor(public http: HttpClient) { }

  public getSheetData(): Observable<any> {
    //const sheetId = '15Kndr-OcyCUAkBUcq6X3BMqKa_y2fMAXfPFLiSACiys';
    //const url = `https://spreadsheets.google.com/feeds/list/${sheetId}/od6/public/values?alt=json`;

    return this.http.get(this.url)
      .pipe(
        map((res: any) => {
          const data = res.feed.entry;

          const returnArray: Array<any> = [];
          if (data && data.length > 0) {
            data.forEach(entry => {
              const obj = {};
              for (const x in entry) {
                if (x.includes('gsx$') && entry[x].$t) {
                  obj[x.split('$')[1]] = entry[x]['$t'];
                }
              }
              returnArray.push(obj);
            });
          }
          return returnArray;
        })
      );
  }

  public getEquityData(): Observable<any> {
    //const sheetId = '15Kndr-OcyCUAkBUcq6X3BMqKa_y2fMAXfPFLiSACiys';
    //const url = `https://spreadsheets.google.com/feeds/list/${sheetId}/od6/public/values?alt=json`;

    return this.http.get(this.equityurl)
      .pipe(
        map((res: any) => {
          const data = res.feed.entry;

          const returnArray: Array<any> = [];
          if (data && data.length > 0) {
            data.forEach(entry => {
              const obj = {};
              for (const x in entry) {
                if (x.includes('gsx$') && entry[x].$t) {
                  obj[x.split('$')[1]] = entry[x]['$t'];
                }
              }
              returnArray.push(obj);
            });
          }
          return returnArray;
        })
      );
  }
}
