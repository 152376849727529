import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Output() onHide = new EventEmitter<boolean>();
  public profilePic:any;
  menuItem:any[]
  constructor(public auth:AuthService,public router: Router) {
    this.profilePic = '/assets/images/logo/guest-icon.jpg';
    this.menuItem = [
                      { route : '/' , icon :'home' ,display:'Home'},
                      { route : 'dashboard' , icon :'dashboard' ,display:'Dashboard'},
                      { route : 'MCalls' , icon :'notifications_active' ,display:'M-Calls',queryParam:{page: "None" , section:
                      'TP'}},
                      { route : 'MCalls' , icon :'outlined_flag' ,display:'IPO' ,queryParam:{page: "IPO", section:
                        'Main Line'}},
                      { route : 'about' , icon :'groups' ,display:'About'},
                    ]
     
                    this.auth.user$.pipe(take(1)).subscribe(user => 
                      {
                        if(user != null && user.roles.admin)
                        {
                          this.menuItem.push({ route : 'operations' , icon :'settings' ,display:'Operations'})
                          this.menuItem.push({ route : 'operations/contactviewer' , icon :'receipt' ,display:'Contact Viewer'})
                        }
                        
                      });
   }

  ngOnInit(): void {
  }

  navigate(menu)
    {
      console.log(menu?.queryParam);
      if(menu?.queryParam)
      {
        this.router.navigate([menu.route],{queryParams: menu.queryParam});
        return this.onHide.emit(true);
      }
      this.router.navigate([menu.route]);
      return this.onHide.emit(true);
    }
}
