<mat-card class="container">
    <mat-card-content>
        <div id="chartAndDataContainer">
            <div id="chartContainer">
    <app-future-index-overview #clusterdBarChart></app-future-index-overview>
</div>
</div>  
</mat-card-content> 
</mat-card>

