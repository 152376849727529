import { Component, ElementRef, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-grouped-bar-chart',
  templateUrl: './grouped-bar-chart.component.html',
  styleUrls: ['./grouped-bar-chart.component.scss']
})
export class GroupedBarChartComponent implements OnInit {
hostElement;
slice;
@Input() groupData;
  constructor(private elemRef: ElementRef) {
    this.hostElement = this.elemRef.nativeElement;
   }

  ngOnInit(): void {
    console.log(this.groupData);
    d3.select(this.hostElement).select('svg').remove();
      this.generateGroupedBarChart();    
  }

  ngOnChanges() {
    if(!!this.groupData){         
     
    }
  }
 OnChanges

  generateGroupedBarChart()
  {
    var container = d3.select(this.hostElement.parentNode);
    // this.width = parseFloat(d3.select(this.hostElement.parentNode.parentNode.parentNode).style("width"))/2; //parseFloat(this.$container.style("width"));
    // // console.log(this.width);
    // // console.log(d3.select(this.hostElement.parentNode.parentNode.parentNode).style("width"));
    // this.height = 60;//parseFloat(this.$container.style("height"));
    var margin = {top: 20, right: 20, bottom: 30, left: 40},
    width = parseFloat(container.style("width")) - margin.left - margin.right,
    height = width*.618 - margin.top - margin.bottom;

    
   
    var x0  = d3.scaleBand().rangeRound([0, width]).padding(.5);
    var x1  = d3.scaleBand();
    var y   = d3.scaleLinear().rangeRound([height, 0]);

    var xAxis = d3.axisBottom(x0)
                        //.tickFormat(d3.timeFormat("Week %V"))
                        .tickValues(this.groupData.map(d=>d.key));

    var yAxis = d3.axisLeft(y)
    .tickFormat((d:any) => {
      if ((d / 100000) >= 1) {
        d = d / 100000 + "L";
      }
      if ((d / 1000) >= 1) {
      d = d / 1000 + "K";
    }
    return d;
  });

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    d3.select(this.hostElement).select('svg').remove();
var svg = d3.select(this.hostElement).append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var categoriesNames = this.groupData.map(function(d) { return d.key; });
    var rateNames       = this.groupData[0].values.map(function(d) { return d.grpName; });

    var minValue = +d3.min(this.groupData, (key:any) => { return d3.min(key.values, (d:any) => { return d.grpValue; });});
    var maxValue = +d3.max(this.groupData, (key:any) => { return d3.max(key.values, (d:any) => { return d.grpValue; });});
    minValue = minValue > 0 ? 0 : minValue;
    console.log(minValue);
    console.log(maxValue);
    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRound([0, x0.bandwidth()]);
    y.domain([minValue, maxValue]);

    
    var div = d3.select("body")
    .style("position","relative")
    .append("div")
    .attr("class", "tooltip")
    .style("position", "absolute")
    .style("text-align", "center")
    .style("font-size", 12)
    .style("color", "#fff")
    .style("width", "auto")
    .style("padding-left", "5px")
    .style("padding-right", "5px")
    .style("background", "#000")
    .style("border", "0px")
    .style("border-radius", "8px")
    .style("pointer-events", "none")
    .style("z-index", "1")
    .style("opacity", 0);	

    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);


    svg.append("g")
      .attr("class", "y axis")
      .style('opacity','0')
      .call(yAxis)
        .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 6)
            .attr("dy", ".71em")
            .style("text-anchor", "end")
            .style('font-weight','bold')
            .text("Value");

    svg.select('.y').transition().duration(500).delay(1300).style('opacity','1');

    this.slice = svg.selectAll(".slice")
      .data(this.groupData)
      .enter().append("g")
      .attr("class", "g")
      .attr("transform",(d:any)=> { return "translate(" + x0(d.key) + ",0)"; });

      this.slice.selectAll("rect")
      .data((d:any)=> { return d.values; })
        .enter().append("rect")
            .attr("width", x1.bandwidth())
            .attr("x", (d:any) =>{ return x1(d.grpName); })
             .style("fill",(d:any) => { return d.grpColor })
             .attr("y",(d:any) =>{ return y(0); })
             .attr("height", (d:any) => { return height - y(0); })
            .on("mouseover", (d:any,b,c) => {
             
              d3.select(c[b]).style("opacity", .7);
                  
              div.transition()
              .duration(200)
              .style('opacity', .9);
     
              div.html(d.grpValue)
              .style('left', (d3.event.pageX) + 'px')
              .style('top', (d3.event.pageY - 28) + 'px');
            })
            .on("mouseout", (d:any,b,c) => {
              this.slice.style("fill", d.grpColor);
              d3.select(c[b]).style("opacity", 1);
                  
              div.html('');
            });


    this.slice.selectAll("rect")
      .transition()
      .delay((d)=> {return Math.random()*1000;})
      .duration(1000)
      .attr("y", (d:any)=> { return y(Math.max(0, d.grpValue)); })
      .attr("height", (d:any)=> { return Math.abs(y(d.grpValue) - y(0)) });

  //     //Legend
  var legend = svg.selectAll(".legend")
      .data(this.groupData[0].values.map(function(d) { return d }).reverse())
  .enter().append("g")
      .attr("class", "legend")
      .attr("transform", function(d,i) { return "translate(0," + i * 20 + ")"; })
      .style("opacity","0");

  legend.append("rect")
      .attr("x", width - 18)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", (d:any)=> { console.log(d); return  d.grpColor; });

  legend.append("text")
      .attr("x", width - 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "end")
      .text((d:any) => {return d.grpName; });

  legend.transition().duration(500).delay(function(d,i){ return 1300 + 100 * i; }).style("opacity","1");

  }

}
