<!-- <app-header (toggleSideBarForMe)="sideBarToggler($event)"></app-header>



<mat-drawer-container>
    <mat-drawer mode="over" [opened]="sideBarOpen"><app-sidebar (onHide)="sideBarToggler($event)"></app-sidebar></mat-drawer>
    <mat-drawer-content>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container> -->
<!-- <app-footer></app-footer> -->


    <!-- ***** Preloader Start ***** -->
    <div id="preloader">
        <div class="jumper">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>  
    <!-- ***** Preloader End ***** -->
    
    
    <!-- ***** Header Area Start ***** -->
    <header class="header-area header-sticky">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <nav class="main-nav">
                        <!-- ***** Logo Start ***** -->
                        <a href="#" class="logo"> <span><img class="logo" src="../../../../assets/images/logo/ProfitKhoj_logo_full_4_1_90.png" height="60px"/></span></a>
                        <!-- ***** Logo End ***** -->
                        <!-- ***** Menu Start ***** -->
                        <ul class="nav">
                            <li class="scroll-to-section"><a href="#welcome" class="active">Home</a></li>
                            <li class="scroll-to-section"><a href="#about">About</a></li>
                            <li class="scroll-to-section"><a href="#services">Services</a></li>
                            
                            <li class="scroll-to-section"><a href="#contact-us">Contact Us</a></li>
                        </ul>
                        <a class='menu-trigger' (click)="toggleMenu()">
                            <span>Menu</span>
                        </a>
                        <!-- ***** Menu End ***** -->
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- ***** Header Area End ***** -->


    <!-- ***** Welcome Area Start ***** -->
    <div class="welcome-area" id="welcome">

        <!-- ***** Header Text Start ***** -->
        <div class="header-text">
            <div class="container">
                <div class="row">
                    <div class="left-text col-lg-6 col-md-6 col-sm-12 col-xs-12" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                      <img src="assets/images/charts/pk_indicator_1.png" class="rounded img-fluid d-block mx-auto" alt="First Vector Graphic">
                       
                        <p>The Profitkhoj Indicator is a tool designed to help traders in making their trading decisions based on specific set of rules that come along with proper risk management.</p>
                        <div class="row">
                          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12"  style="padding-top:20px;">
                            <a href="#about" class="main-button-slider">Find Out More</a> 
                          </div>
                           <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding-top:20px;">
                            <a href="#services" class="main-button-slider">Get your 14-days free trail</a> 
                           </div>
                           
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                        <img src="assets/images/slider-icon.png" class="rounded img-fluid d-block mx-auto" alt="First Vector Graphic">
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Header Text End ***** -->
    </div>
    <!-- ***** Welcome Area End ***** -->


    <!-- ***** Features Big Item Start ***** -->
    <section class="section" id="about">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                    <img src="assets/images/left-image.png" class="rounded img-fluid d-block mx-auto" alt="App">
                </div>
                <div class="right-text col-lg-5 col-md-12 col-sm-12 mobile-top-fix">
                    <div class="left-heading">
                        <h5>How to apply indicator on Trading view chart?</h5>
                        <img src="assets/images/charts/pk_indicator_2.png" class="rounded img-fluid d-block mx-auto" alt="App">
                    </div>
                    <div class="left-text">
                        <p><br>Once you subscribe to the indicator,you will be given access to the indicator by our team.
                          <br>
                          Just open any chart on <strong>Trading View</strong> > go to indicator tab ,then <strong>Invite-only script</strong> tab, 
                          then you will be able to see <strong>Profitkhoj Indicator</strong>,
                          go ahead and click on it to load the indicator onto your chart.
                       </p>
                        <a href="#about2" class="main-button">Discover More</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="hr"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- ***** Features Big Item End ***** -->


    <!-- ***** Features Big Item Start ***** -->
    <section class="section" id="about2">
        <div class="container">
            <div class="row">
                <div class="left-text col-lg-8 col-md-12 col-sm-12 mobile-bottom-fix">
                    <div class="left-heading">
                        <h5>Profitkhoj indicator guidlines and rules.</h5>
                    </div>
                    <ul>
                        <li>
                         
                            <div class="text">
                              <h6>1) The Buy/Sell Signal</h6>
                              <img src="assets/images/charts/pk_indicator_3.png" alt="" class="w-100">
                               
                                <p>The Buy or Sell Signal is the first thing that we have to notice in order to move further to decode whether to take the trade or not. See figure above</p>
                            </div>
                        </li>
                        <li>
                          <div class="text">
                            <h6>2) The Price Action Conformation Indication(PAC)(	<i style="color:blue" class="fa fa-sort-asc"></i><i style="color:red"class="fa fa-sort-desc"></i>)</h6>
                            <img src="assets/images/charts/pk_indicator_4.png" alt="" class="w-100">
                             
                              <p>Once a <i style="color:blue;" class="fa fa-sort-asc"></i> is plotted it suggests that the trend is UP unless <i style="color:red;" class="fa fa-sort-desc"></i> is plotted. If the stock or futures is in a down trend i.e last signal was <i style="color:red;" class="fa fa-sort-desc"></i> 
                                then we require a <i style="color:blue;" class="fa fa-sort-asc"></i> along with a buy signal. See above figure.
                                If a sell signal did not give PAC <i style="color:red"class="fa fa-sort-desc"></i> then the next buy signal will not plot PAC <i style="color:blue;" class="fa fa-sort-asc"></i> as it is already in uptrend. See figure given below.
                                </p>
                                <img src="assets/images/charts/pk_indicator_5.png" alt="" class="w-100" style="margin-bottom:20px;">
                          </div>
                        </li>
                        <li>
                            <div class="text">
                              <h6>3) Target & Stoploss</h6>
                              <img src="assets/images/charts/pk_indicator_6.png" alt="" class="w-100">
                               
                                <p>Once a signal is generated,target & stoploss will start plotting itself. 
                                  <br>
                                  Initially entry point in <strong>Orange</strong> color for sell & <strong>Green</strong> color for buy(new Update).
                                   4 Targets in <strong>Blue</strong> color and stoploss in <strong>Red</strong> color is plotted.
                                   As market move more towards our trade further targets up to Target 10 will be plotted gradually. Maximum limit is of 10 Targets as of now. 
                                   Once a contra signal is generated it will start plotting stoploss, entry point and targets for that signal. See above figure sell signal.
                                  </p>
                            </div>
                        </li>
                        <li>
                          <div class="text">
                            <h6>4) The Dynamic Value Area (DVA)</h6>
                            <img src="assets/images/charts/pk_indicator_7.png" alt="" class="w-100">
                             
                              <p>These are Dynamic Support & resistance zones. 
                                <br>
                                General assumption is that Mr.Market is bullish above this area & bearish below this area. 
                                Ideal set up is where we get a Buy-signal above this area & Sell signal below this area, although there are exemptions to trade these signals too but with certain additional criteria’s that we will see later.
                                </p>
                          </div>
                      </li>
                      <li>
                        <div class="text">
                          <h6>5) Fixed Support & Resistance Zone</h6>
                          <img src="assets/images/charts/pk_indicator_8.png" alt="" class="w-100">
                           
                            <p>These Fixed gray blocks are plotted automatically to show nearest support & resistance area to alert a trader before taking a trade so that signals generated very close to these areas could be avoided (At least target 1 should be within this area as shown in figure above left side)
                              </p>
                        </div>
                    </li>
                    <li>
                      <div class="text">
                        <h6>6) Tables</h6>
                        <img src="assets/images/charts/pk_indicator_9.png" alt="" class="w-100">
                         
                          <p>The table has 7 components namely
                            <br><br>
                            a.Momentum :- It shows 4 values(Strong,Trending,Weak,Exhausted) in that order of strongness. 
                            It states the status of the current trend based on Buy/Sell Signal. 
                            If last generated signal is Buy then it will give status of the Bullish trend & if last generated signal is Sell then it will give status of Bearish trend. To Buy or Sell The Momentum should be Strong or Trending. After Entering a Trade It is advised to keep an eye on this status If while Entering a trade it was strong & later it becomes trending or weak or exhausted it’s better to trail SL. If at any point the Momentum becomes Strong & You have booked some profits then trailing is advised unless the momentum becomes weak to take maximum advantage of strong momentum.
                            <br><br>
                            b.+DI :- Value of +DI is of importance when a Buy signal is generated it should be typically above 25 along with at least trending indication on the Momentum, a +DI value of  minimum 22-23 along with Strong indication on the Momentum also works pretty well.
                            <br><br>
                            c.-DI :- Value of -DI is of importance when a Sell signal is generated it should be typically above 25 along with at least trending indication on the Momentum, a -DI value of  minimum 22-23 along with Strong indication on the Momentum also works pretty well.
                            <br><br>
                            d.UP% :- This value roughly generates the probability of UP move based or volumes.Value of UP% is of importance when a Buy signal is generated it should be typically equal to 40 or more. DP% of 40 or less is to be preferred along with this. An exception to this rule can be made if a Buy signal is generated after a huge gap down, in that case the value of UP% should be at least 10 & DP% less than 0r equal to 60 is preferred.
                            <br><br>
                            e.DP% :- This value roughly generates the probability of DOWN move based or volumes. Value of DP% is of importance when a Sell signal is generated it should be typically equal to 40 or more. UP% of 40 or less is preferred along with this. An exception to this rule can be made if a Sell signal is generated after a huge gap up, in that case the value of DP% should be at least 10 & UP% less than or equal to 60 is preferred,
                            <br><br>
                            f.Trend :-It takes into account RSI, MACD and Volumes to determine short term trend. Trend should be Bullish for a Buy signal & Bearish for Sell signal. 
                            NOTE:-  d.UP% , e.DP% & Trend value will show 0,0& NA respectively if any instrument is selected which do not contain volume such as indexes Nifty50/BankNifty. Nifty1! & BankNifty1! Could be used instead.
                            <br><br>
                            g.MTF Trend :-  This calculates value of RSI on multiple time frames & generates a signal. For Buy signal the MTF Trend should be Bullish or Neutral & for Sell Signal MTF trend should be Bearish or Neutral.
                            
                            </p>
                      </div>
                  </li>
                  <li>
                    <div class="text">
                      <h6>7) Colored Bars</h6>
                      <img src="assets/images/charts/pk_indicator_10.png" alt="" class="w-100">
                       
                        <p>Blue is an up candle with volume greater than recent volumes, Maroon is a down candle with volume greater than recent volume, Pink is low volume candle & Silver is Ultra high volume candle.
                          <br><br>
                          The Set UP: Ideal Set up is when we have a buy signal and market is already in up trend or we have a sell signal & market is already in down trend but we hardly see such setups in such markets. So, a plan has been devised to trade counter trend.
                          <br><br>
                          1.In the picture above we see a sell signal on left right into DVA, when such a set-up is seen we wait for a retracement or atleast 1 candle to close above the orange line then a close below orange line will be entry. The market did not test the DVA before giving a sell signal.
                          <br><br>
                          2.We see a buy signal also right into DVA but here the market had already tested the DVA and the buy signal close above the previous high making a higher close which is a valid price action entry.
                          <br><br>                          
                          3.We see another sell signal right into DVA again, this time sell signal was not confirmed as a candle did not close below orange line.

                          </p>
                    </div>
                </li>
                    </ul>
                </div>
         <!--        <div class="right-image col-lg-4 col-md-12 col-sm-12 mobile-bottom-fix-big" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                    <img src="assets/images/right-image.png" class="rounded img-fluid d-block mx-auto" alt="App">
                </div> -->
            </div>
        </div>
    </section>
    <!-- ***** Features Big Item End ***** -->


    <!-- ***** Features Small Start ***** -->
    <section class="section" id="services">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="item service-item">
                        <div class="icon">
                            <i><img src="assets/images/service-icon-01.png" alt=""></i>
                        </div>
                        <h1>To use profitkhoj indicator,share your trading view I'd.</h1>
                        <h3>We are offering a free 14 day trial. After the free trail period, if you wish to continue the charges are </h3>
                        <div class="row justify-content-center">
                          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 divTotTrade">
                             <h3>For 1 Month</h3>
                             <h1>&#8377; {{onemonthprice}}</h1>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 divWinPerc">
                            <h3>For 3 Month</h3>
                            <h1>&#8377; {{threemonthprice}}</h1>
                          </div>
                        </div>
                     
                        <h1>Contact: Profitkhoj Admin Whatsapp <strong>{{admincontact}}</strong></h1>
                    </div>
                  
                </div>
            </div>
           
        </div>
    </section>
    <!-- ***** Features Small End ***** -->

    <!-- ***** Footer Start ***** -->
    <footer>
        <div class="container" id="contact-us">
            <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                    <p class="copyright">Copyright &copy; 2022 Team Profitkhoj
                </div>
                <div class="col-lg-5 col-md-12 col-sm-12">
                    <ul class="social">
                        <li><a [href]="urls[1]"><i class="fa fa-facebook"></i></a></li>
                        <li><a [href]="urls[0]"><i class="fa fa-twitter"></i></a></li>
                        <li><a [href]="urls[2]"><i class="fa fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
    
  

