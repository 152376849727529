import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { MessagingService } from './services/messaging.service';
declare var $: any; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  sideBarOpen = false;
  message:BehaviorSubject<any>;
  admincontact = "+917303145135";
  onemonthprice = "880.00";
threemonthprice = "2499.00";
urls = ["https://twitter.com/profitkhoj?t=a0uoKH-ZNzwGRgLZsAy2Aw&s=08","https://www.facebook.com/profitkhoj","https://instagram.com/profitkhoj?igshid=YmMyMTA2M2Y="]
  constructor(private messagingService: MessagingService,private snackBar: MatSnackBar,private breakpointObserver: BreakpointObserver ) { }

  ngOnInit() { 
    /* this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.messagingService.requestPermission();
      }
    });
   // this.messagingService.deleteToken();

  this.messagingService.receiveMessage();
  this.message = this.messagingService.currentMessage;

  this.message.subscribe(msg => {
    if(msg == null)
      return;
    this.snackBar.open(msg?.notification?.title+": "+ msg?.notification?.body, 'cancel' ,{ duration: 2000 })
  }) */
   
  }

  toggleMenu()
  {
    $(".menu-trigger").toggleClass("active");
    $(".header-area .nav").slideToggle(200);
  }
  sideBarToggler(event) {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
