import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MarketDataService {
  private opChainEodUrl = 'https://kaizentrader.herokuapp.com/getoptdata/NIFTY/15Apr2021'
  private spotPriceUrl:string = 'https://kaizentrader.herokuapp.com/getspotprice';
  private opChainUrl:string = 'https://kaizentrader.herokuapp.com/getoptionchain';
  private spUrl:string = 'https://kaizentrader.herokuapp.com/getstrikeprices';
  private expUrl:string = 'https://kaizentrader.herokuapp.com/getexpiry';//'https://www1.nseindia.com/homepage/Indices1.json';
  private opChainWithTypeUrl = "https://kaizentrader.herokuapp.com/getoptionchainwithtype";///NIFTY/27AUG2020/10700/CE
  constructor(private httpClient: HttpClient) { }


  public getOptionEODData(idxtype)
  {
    return this.httpClient.get<any[]>(this.opChainEodUrl);
  }
  public getExpiry(idxtype)
  {
    return this.httpClient.get<any[]>(this.expUrl+"/"+idxtype);
  }


  public getStrikePrice(idxtype,expiry)
  {
    return this.httpClient.get<any[]>(this.spUrl+"/"+idxtype+"/"+expiry);
  }

  public getOptionData(idxtype,expiry,strikeprice)
  {
    return this.httpClient.get(this.opChainUrl+"/"+idxtype+"/"+expiry+"/"+strikeprice);
  }

  public getOptionDataWithType(idxtype,expiry,strikeprice,optype)
  {
    return this.httpClient.get(this.opChainWithTypeUrl +"/"+idxtype+"/"+expiry+"/"+strikeprice+"/"+optype);
  }

  public getSpotPrice(idxtype,expiry)
  {
    return this.httpClient.get(this.spotPriceUrl+"/"+idxtype+"/"+expiry);
  }
}
