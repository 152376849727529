import { HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
@Directive({
    selector: '[userHandleCheckValidator][ngModel],[userHandleCheckValidator][FormControl]',
    providers: [
        {provide: NG_ASYNC_VALIDATORS, useExisting: UserHandleCheckDirective, multi: true}
    ]
})
export class UserHandleCheckDirective implements AsyncValidator{

    constructor(private userService:UserService,private http: HttpClient,) {
    }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        console.log("sds");
        const obs = this.userService.CheckHandleExist(control.value).pipe(map((isUsed)=>{
            return !isUsed ? null : {
                userHandleCheckValidator: 'Name exists already.'
            };
        }));
        return obs;
    }
}