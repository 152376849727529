import { Component, OnInit } from '@angular/core';
import { MarketDataService } from 'src/app/services/market-data.service';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import * as d3 from 'd3';

export interface OptionChain {
  changeInOi: number;
  strikeprice: number;
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  postTab = [{ displayname :'M-Call', val : 'TP'},{ displayname :'News & Alert', val : 'NA'}]
  displayedColumns: string[] = ['changeInOi', 'strikeprice'];
  dataSource : OptionChain[] = []
  niftyOI:any[];
  niftyCOI:any[];
  constructor(private marketDataService: MarketDataService) {
    
//     this.niftyOC = [
//       { key: 13900, values:
//         [
//           {grpName:'Call OI', grpValue:50000, grpColor:'#299691'},
//           {grpName:'Put OI', grpValue:40000 , grpColor:'#ef476f'}
//         ]
// },
//       { key: 14000, values:
//         [
//           {grpName:'Call OI', grpValue:46000, grpColor:'#299691'},
//           {grpName:'Put OI', grpValue:35000 ,  grpColor:'#ef476f'}
//         ]
// },
//       { key: 14500, values:
//         [
//           {grpName:'Call OI', grpValue:26000, grpColor:'#299691'},
//           {grpName:'Put OI', grpValue:15000 ,  grpColor:'#ef476f'}
//         ]
// },
// { key: 14600, values:
//         [
//           {grpName:'Call OI', grpValue:30000 , grpColor:'#299691'},
//           {grpName:'Put OI', grpValue:17000 ,  grpColor:'#ef476f'},
//         ]
// }];

   }

  ngOnInit(): void {

    this.marketDataService.getSpotPrice('NIFTY','15Apr2021').toPromise().then(
      (sp:any) => {
        console.log(sp[0].spot_price);
        this.marketDataService.getOptionEODData('Nifty').toPromise().then(data => {
          var filterTotolOIData = data.filter(row => row.sp >= sp[0].spot_price-200 && row.sp <= sp[0].spot_price+200);
          this.niftyOI = d3.nest<any,any>()
          .key(function(d) { return d.sp })
          .rollup(function(d){
            
                  var d2  =  {key: d[0].sp,values: []} 
                  var arr = []
                  d.forEach(function(d){
                    arr.push(d);
                  })
                  d2.values = arr.map(d => { return {
                                            grpName : d.type == 'CE'? 'Call' : 'Put'
                                            ,grpValue: d.oi 
                                            ,grpColor :d.type == 'CE'? '#299691': '#ef476f'
                                          }}
                                    );
                  //console.log("rollup d", d, d2);
                  return d2;
                }) 
          .entries(filterTotolOIData)
          .map(function(d){ return d.value; });


          this.niftyCOI = d3.nest<any,any>()
          .key(function(d) { return d.sp })
          .rollup(function(d){
            
                  var d2  =  {key: d[0].sp,values: []} 
                  var arr = []
                  d.forEach(function(d){
                    arr.push(d);
                  })
                  d2.values = arr.map(d => { return {
                                            grpName : d.type == 'CE'? 'Call' : 'Put'
                                            ,grpValue: d.coi 
                                            ,grpColor :d.type == 'CE'? '#00596f': '#fcb46a'
                                          }}
                                    );
                  //console.log("rollup d", d, d2);
                  return d2;
                }) 
          .entries(filterTotolOIData)
          .map(function(d){ return d.value; });
        })
      }
    )
  }

}
