import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-win-probablity-gauge',
  templateUrl: './win-probablity-gauge.component.html',
  styleUrls: ['./win-probablity-gauge.component.scss']
})
export class WinProbablityGaugeComponent implements OnInit {
  hostElement; // Native element hosting the SVG container
  $container:any;
  width:any;
  height:any;
  chart_w:any;
  resultPos:any;
  //result = 0.50;
  text_margins:any;
  chart_y_pos:any;
  gauge_h=30;
  @Input() result=0;    
  constructor(private elemRef: ElementRef) { 
    this.hostElement = this.elemRef.nativeElement;
    //this.result = this.winpercent/100;
  }

  ngOnInit(): void {
    this.genarateWinProbablityIndicator();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event)
  {
    d3.select(this.hostElement).select('svg').remove();
    this.genarateWinProbablityIndicator();
  //   console.log("HI");
  //   this.$container = d3.select(this.hostElement.parentNode);
  // this.width =  parseFloat(this.$container.style("width"));
  // this.height = 60;//parseFloat(this.$container.style("height"));
  
   
  //   this.chart_w = this.width;
  //   this.resultPos = this.chart_w * this.result;
  
  //   this.text_margins = {top: this.chart_y_pos + this.gauge_h + 20, right: 10, bottom: 0, left: 10};
  
    
  //   d3.select("line")
  //   .attr("x1", this.resultPos)
  //   .attr("y1", this.chart_y_pos )
  //   .attr("x2", this.resultPos )
  //   .attr("y2", this.gauge_h + this.chart_y_pos );
  
  //   d3.select("circle")
  //   .attr("cx", this.resultPos)
  //   .attr("cy", (this.gauge_h + this.chart_y_pos) / 2 );
  
  //   d3.select(".rightLabel")
  //   .attr("x", this.chart_w )
  //   .text( "width: " + this.width );
  
  //   d3.select(".rightPrcnt")
  //   .attr("x", this.chart_w );
   
  }
  

genarateWinProbablityIndicator()
{
  this.$container = d3.select(this.hostElement.parentNode);
  this.width = parseFloat(d3.select(this.hostElement.parentNode.parentNode.parentNode).style("width"))/2; //parseFloat(this.$container.style("width"));
  // console.log(this.width);
  // console.log(d3.select(this.hostElement.parentNode.parentNode.parentNode).style("width"));
  this.height = 60;//parseFloat(this.$container.style("height"));

//window.onresize = resize;


// Tick mark

var LF = 30;


this.chart_w = this.width;
this.chart_y_pos = 0;

this.resultPos = this.chart_w * this.result;

this.text_margins = {top: this.chart_y_pos + this.gauge_h + 20, right: 10, bottom: 0, left: 10};

// Chart size -----------
d3.select(this.hostElement).select('svg').remove();
var svg = d3.select(this.hostElement).append("svg")
.attr("width", this.width)
.attr("height", this.height );

// this.$container = d3.select(this.hostElement.parentNode);
// this.width = parseFloat(this.$container.style("width"));
// this.height = parseFloat(this.$container.style("height"));

var gradient = svg.append("svg:defs")
  .append("svg:linearGradient")
    .attr("id", "gradient")
    .attr("x1", "0%")
    .attr("y1", "0%")
    .attr("x2", "100%")
    .attr("y2", "0%")
    .attr("spreadMethod", "pad");

gradient.append("svg:stop")
    .attr("offset", "0%")
    .attr("stop-color", "#c00")
    .attr("stop-opacity", 1);

gradient.append("svg:stop")
    .attr("offset", "50%")
    .attr("stop-color", "yellow")
    .attr("stop-opacity", 1);


gradient.append("svg:stop")
    .attr("offset", "100%")
    .attr("stop-color", "#0c0")
    .attr("stop-opacity", 1);

svg.append("g")
	.append("rect")
  .attr("x", 0 )
  .attr("y", this.chart_y_pos )
  .attr("width", this.width)
  .attr("height", this.gauge_h )
  .style("fill", "url(#gradient)");


/****************************************
* Text, titles
*****************************************/

// Left percentage indicator
// svg.append("g")
// 	.append("text")
//   .attr("x", 0)
//   .attr("y", this.text_margins.top )
//   .text( "0%" );
let winpercent = (this.result * 100).toFixed(2);
svg.append("g")
	.append("text")
  .attr("x", this.width/2)
  .attr("y", this.text_margins.top)
  .style("font-family","calibri")
  .style("font-style","italic")
  .style("font-size","14")
  .style("color","#00bcd4")
  .text( winpercent+ " % hit rate" );

// Right percentage indicator

// svg.append("g")
// 	.append("text")
// 	.classed("rightPrcnt", true )
//   .attr("x", this.chart_w )
//   .attr("y", this.text_margins.top )
// 	.attr("text-anchor", "end")
//   .text( "100%" );

// svg.append("g")
// 	.append("text")
// 	.classed("rightLabel", true )
//   .attr("x", this.chart_w )
//   .attr("y", this.text_margins.top + LF )
// 	.attr("text-anchor", "end")
//   .text( "width: " + this.chart_w );

/****************************************
* Result
*****************************************/


var tickMark = svg.append("g");

tickMark.append("line")
  .attr("x1", this.resultPos)
	.attr("y1", this.chart_y_pos )
  .attr("x2", this.resultPos )
	.attr("y2", this.gauge_h + this.chart_y_pos )
	.attr("stroke-width", 3)
	.attr("stroke", "#00bcd4");


tickMark.append("circle")
  .attr("cx", this.resultPos)
  .attr("cy", (this.gauge_h + this.chart_y_pos) / 2 )
  .attr("fill", "#00bcd4")
	.attr("r", 10);

  
//this.onResize();

}



}
