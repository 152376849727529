<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" fxLayoutGap="32px">
   
    <div fxFlex="0 1 calc(50%-32px)">
    <h1>About Us</h1>
    <br>
    <p color="light">Profit Khoj app helps you follow trades & views of expert traders, news and alerts and IPO 
        <br>
        <br>
        Profit Khoj helps you to follow trades of experts and learn to trade by watching them trade.
        Our expert traders announce their trades &  view for Intraday, Long-term, Short-term, medium term and Option Call  in the M-calls section and you receive them instantly on your phonevia Push Notifications.
        <br>
        <br>
        We are a group of traders trading successfully since year 2015 and we have a profitable trading system in place that has helped us to find the correct opportunities in the market. Being an part time trader is not easy so this app is for those traders who are willing to learn and at the same time they can follow expert traders. Expert traders in our group announce their trades and views in our M-calls section so that you can watch them and learn. We have also got a news & alert tab to keep you updated about the market & an IPO section to guide you throughout the IPO process.
        <br>
        <br>
        We don’t promise any sure income/return/profit as trading involves risk of losing your capital. We are not responsible for any losses or profits incurred by acting on our posts and views. As an investor/trader you should be aware of risk in trading following our trades and views.Kindly read our full disclaimer before proceeding to avail our services.
        <br>
        <br>
      </p>
     <hr>
      <p>
          This app has an exciting feature of Instant Push Notification.<br>
          Our Experts shares their Trades & Views regarding:<br>
         
      </p>
      <ul color="primary">
          <li>Intraday Trading</li>
          <li>Short Term Trading</li>
          <li>Positional Trading</li>
          <li>Long term Trading</li>
          <li>BTST Trading</li> 
          <li>Option Tarding</li>
          <li>Furture Tarding</li>
        </ul>
    </div>
    </div>
