import { Component, OnInit, OnDestroy, AfterContentInit, ViewChild } from '@angular/core';;

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy, AfterContentInit  {
  //@ViewChild('clusterdBarChart', { static: true }) chart: FiOverviewChartComponent;

  chartFutureIndexData = [];
  constructor() { }

  ngOnInit(): void {
  }

  
  ngOnDestroy() {
   
  }

  ngAfterContentInit() {
    this.initialize();
  }

  initialize()
  {
   
  }

  getFutDataFromCsv()
  {

  }


}
