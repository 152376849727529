<div fxLayout="column">
  <!-- <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutGap="32px" class="widget-margin">
    <mat-card  fxFlex="0 1 calc(50%-32px)">
      <mat-card-header>
        <mat-card-title>
            Nifty - Total Open Interest(EOD)
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
      <app-grouped-bar-chart  *ngIf="niftyOI" [groupData]="niftyOI"></app-grouped-bar-chart>
    </mat-card-content>
    </mat-card >
    <mat-card  fxFlex="0 1 calc(50%-32px)">
      <mat-card-header>
        <mat-card-title>
          Nifty - Change In Open Interest(EOD)
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
      <app-grouped-bar-chart  *ngIf="niftyCOI" [groupData]="niftyCOI"></app-grouped-bar-chart>
      
  </mat-card-content>
    </mat-card >
  </div> -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
    
    <app-eodmarket-analysis></app-eodmarket-analysis>
    <!-- <app-message [messagetype]="'info'" [message]="'Work in progress.This message will be removed once module is ready'"></app-message>
    <mat-card >
      <mat-card-title>
        Participant-Wise Open Interest
      </mat-card-title>
      <mat-card-content>
          <div id="chartAndDataContainer">
              <div id="chartContainer">
      <app-future-index-overview #clusterdBarChart></app-future-index-overview>
    </div>
    </div>  
    </mat-card-content> 
    </mat-card> -->
    
  </div>
</div>

