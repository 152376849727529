import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-eodmarket-analysis',
  templateUrl: './eodmarket-analysis.component.html',
  styleUrls: ['./eodmarket-analysis.component.scss']
})
export class EODMarketAnalysisComponent implements OnInit {
  public eodData$:Observable<any>;
  loggedInUser;
  constructor(public afs:AngularFirestore,private auth:AuthService) {
    this.auth.user$.pipe(take(1)).subscribe(user => 
      {
        if(user != null)
        {
          this.loggedInUser = user;
        }
        
      });
    this.getEODMarketData();
   }

  ngOnInit(): void {
  }

  getEODMarketData()
  {
    const eodDataRef: AngularFirestoreDocument<any> = this.afs.doc(`eod_market_data/MD`);
    this.eodData$ = eodDataRef.valueChanges();
    ///eodDataRef.valueChanges().subscribe(data => console.log(data));
  }

}
