import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list'
import {MatCardModule} from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import {  HttpClientModule } from '@angular/common/http';
import { LinkyfyPipe } from './pipes/linkyfy.pipe';
import { RouterModule } from '@angular/router'; 
import {LayoutModule} from '@angular/cdk/layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AppMessageComponent } from './components/app-message/app-message.component';
import { InfiniteScrollComponent } from './directives/scrollable.directive';
import { BoldifyPipe } from './pipes/boldify.pipe';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { UserHandleCheckDirective } from './directives/user-handle-check.directive';
import { MasterRouterLinkDirective } from './directives/master-router-link.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DialogConfirmPostComponent } from '../modules/mcall/dialog-confirm-post/dialog-confirm-post.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LinkyfyPipe,
    AppMessageComponent,
    InfiniteScrollComponent,
    BoldifyPipe,
    LoadingDialogComponent,
    UserHandleCheckDirective,
    MasterRouterLinkDirective,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule, 
    MatButtonModule, 
    MatMenuModule, 
    MatListModule,
    MatCardModule,
    MatProgressBarModule,
    FlexLayoutModule,
    HttpClientModule,
    LayoutModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  exports:[
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LinkyfyPipe,
    AppMessageComponent,
    InfiniteScrollComponent,
    BoldifyPipe,
    LoadingDialogComponent,
    UserHandleCheckDirective
  ]
})
export class SharedModule { }
