import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging,private userService:UserService) {

    // this.angularFireMessaging..subscribe((_messaging) => 
    // {
    //   _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //   _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    // })
  }

  requestPermission() 
  {
    this.angularFireMessaging.requestToken.subscribe((token) => 
    {
      this.userService.saveTokenToFirestore(token).then(()=>{
        //this.userService.setUserProperty({isNotificationEnabled:true});
      });
    },
    (err) => 
    {
      console.error('Unable to get permission to notify.', err);
    });
  }

  deleteToken() {
    this.angularFireMessaging.getToken.subscribe(token => console.log(token));
      // .pipe(mergeMap((token) =>
      //   {
      //     console.log(token);
      //     this.angularFireMessaging.deleteToken(token);
      //   }
      //   ))
      // .subscribe(
      //   (token) => { console.log('Token deleted!'+token); },
      // );
  }

  receiveMessage() {
  this.angularFireMessaging.messages.subscribe(
  (payload) => {
  console.log("new message received. ", payload);
  this.currentMessage.next(payload);
  })
  }
}
