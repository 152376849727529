<h3 mat-dialog-title>Adjust Image</h3>
<mat-dialog-content class="mat-typography">
    <div class="fileSelector">
        <input type="file" accept="image/x-png,image/jpeg,image/gif" (change)="UploadPic($event)" />
    </div>
    <div class="imgEditorDiv" *ngIf="showImageEditor">
        <div class="imgTool">
            <mat-label>Resize Image</mat-label>
            <mat-slider style="width:200px"
            class="example-margin"
            [disabled]="disabled"
            [max]="max"
            [min]="min"
            [step]="step"
            [thumbLabel]="thumbLabel"
            [tickInterval]="getSliderTickInterval()"
            [(ngModel)]="value"
            (change)="onZoom($event)">
        </mat-slider>
        </div>
        <div class="imgSelector">
            <image-cropper
                [imageBase64]="imageChangedEvent"
                [maintainAspectRatio]="maintainAspectRatio"
                [aspectRatio]="aspectRatio"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
                [transform]="transform"
            ></image-cropper>
        </div>
    </div>
   
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="croppedImage" cdkFocusInitial>Done</button>
  </mat-dialog-actions>
