<div class="maincontainer">
  <div *ngIf="loggedInUser as luser" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" fxLayoutAlign.xs="stretch" fxLayoutGap="32px" fxLayoutGap.xs="0px">
    <div fxFlex="0 1 25%">
        <div class="divTotTrade">
            <h4 class="wid-h4-fontsize wid-fontstyle">Total Trade</h4>
            <h2 class="wid-h2-fontsize wid-fontstyle text-right">
                <mat-icon class="pull-left wid-h2-fontsize">import_contacts</mat-icon>
                <span>{{luser.stats.totaltrade}}</span>
            </h2>
        </div>
    </div>
    <div fxFlex="0 1 25%">
        <div class="divWinPerc">
            <h4 class="wid-h4-fontsize wid-fontstyle">Winning Trade</h4>
            <h2 class="wid-h2-fontsize wid-fontstyle text-right">
                <mat-icon class="pull-left wid-h2-fontsize">thumb_up_outline</mat-icon>
                <span>{{(luser.stats.winningtrade/(luser.stats.winningtrade+luser.stats.losingtrade))*100 | number : '1.2-2'}}</span>
            </h2>
        </div>
    </div>
    <div fxFlex="0 1 25%">
        <div class="divCurrActive">
            <h4 class="wid-h4-fontsize wid-fontstyle">Active Trade</h4>
            <h2 class="wid-h2-fontsize wid-fontstyle text-right">
                <mat-icon class="pull-left wid-h2-fontsize">lightbulb_outline</mat-icon>
                <span>{{luser.stats.activetrade}}</span>
            </h2>
        </div>
    </div>
    <div fxFlex="0 1 25%">
        <div class="divOvrAllGain">
            <h4 class="wid-h4-fontsize wid-fontstyle">Overall % Gain</h4>
            <h2 class="wid-h2-fontsize wid-fontstyle text-right">
                <mat-icon class="pull-left wid-h2-fontsize">trending_up</mat-icon>
                <span>{{luser.stats.overallgain| number : '1.2-2'}}%</span>
            </h2>
        </div>
    </div>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center" fxLayoutGap="32px">
        <div fxFlex="0 1 100%">
            <div class="mat-elevation-z8 divTable">
                <table mat-table [dataSource]="dataSource">
              
                  <!-- Position Column -->
                  <!-- <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef> No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                  </ng-container> -->
              
                  <!-- Name Column -->
                  <ng-container matColumnDef="companycode">
                    <th mat-header-cell *matHeaderCellDef> Stock </th>
                    <td mat-cell *matCellDef="let element"> <b>{{element.companycode}}</b> </td>
                  </ng-container>
              
                  <!-- Weight Column -->
                  <ng-container matColumnDef="cmp">
                    <th mat-header-cell *matHeaderCellDef> Buy @ </th>
                    <td mat-cell *matCellDef="let element" class="text-right">   
                        <span class="pull-left">{{element.cmp}}</span>
                        <mat-icon *ngIf="element.slbuyhit" class="slbuy-hit">check_circle_outline</mat-icon></td>
                  </ng-container>
              
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="sl">
                    <th mat-header-cell *matHeaderCellDef> Stop Loss </th>
                    <td mat-cell *matCellDef="let element" class="text-right">   
                        <span class="pull-left">{{element.sl}}</span>
                        <mat-icon *ngIf="element.slhit" class="sl-hit">check_circle_outline</mat-icon></td>
                  </ng-container>
    
                  <ng-container matColumnDef="t1">
                    <th mat-header-cell *matHeaderCellDef> Target 1</th>
                    <td mat-cell *matCellDef="let element" class="text-right"> 
                        <span class="pull-left">{{element.t1}}</span>
                        <mat-icon *ngIf="element.t1hit" class="target-hit">check_circle_outline</mat-icon>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="t2">
                    <th mat-header-cell *matHeaderCellDef> Target 2</th>
                    <td mat-cell *matCellDef="let element" class="text-right"> 
                        <span class="pull-left">{{element.t2}}</span>
                        <mat-icon *ngIf="element.t2hit" class="target-hit">check_circle_outline</mat-icon>
                    </td>
                  </ng-container>
    
                  <ng-container matColumnDef="createdDate">
                    <th mat-header-cell *matHeaderCellDef> Posted On </th>
                    <td mat-cell *matCellDef="let element">{{element.createdDate | date : 'dd-MMM-yyyy hh:mm a'}}</td>
                  </ng-container>
    
                  <ng-container matColumnDef="percentGain">
                    <th mat-header-cell *matHeaderCellDef> % Gain </th>
                    <td mat-cell *matCellDef="let element" [style.color]="getColorCode(element)"> <b>{{element.percentGain}}</b></td>
                  </ng-container>
    
                 
              
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              
                <mat-paginator [length]="pageLength" (page)="pageChanged($event)"  [showFirstLastButtons]="false"
                [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
              </div>
        </div>
  </div>
</div>
