import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlider } from '@angular/material/slider';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-photo-cropper-dialog',
  templateUrl: './photo-cropper-dialog.component.html',
  styleUrls: ['./photo-cropper-dialog.component.scss']
})
export class PhotoCropperDialogComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  aspectRatio : number;

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 200;
  min = 0;
  showTicks = false;
  step = 10;
  thumbLabel = true;
  value = 100;
  vertical = false;
  tickInterval = 1;

scaleFactor : number = 100;
scale : number = 1.0;
transform: ImageTransform = {  scale: 1 };
showImageEditor = false;
useCommpression = false;
maintainAspectRatio = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
    //this.imageChangedEvent = data.event
    this.aspectRatio = data.aspectRatio;
    this.useCommpression = data.useCompression;
    if(data.maintainAspectRatio != null)
      this.maintainAspectRatio = data.maintainAspectRatio
  }


  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded(image: HTMLImageElement) {
   
     

  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  compressImage(file:Blob): Promise<string>
  {
    return new Promise<string>((resolve,reject)=>{
     
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (event) =>
      {
        var imgElement = new Image();
        imgElement.src = event.target.result.toString();
        imgElement.onload = (e)=>
        {
          
          var canvas = document.createElement("canvas");
          
          var ctx = canvas.getContext("2d");
          
      
          var ratio = 1;
  
          // defining cause it wasnt
          var maxWidth = 1500,
              maxHeight = 1500;
      
          if(imgElement.width > maxWidth)
                  ratio = maxWidth / imgElement.width;
          else if(imgElement.height > maxHeight)
                  ratio = maxHeight / imgElement.height;
  
                  canvas.width = imgElement.width * ratio;
                  canvas.height = imgElement.height * ratio;
                  ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);
                  resolve(ctx.canvas.toDataURL('image/png',1));
        };
      }
    });
 
   
  }

  readImageData(file:Blob): Promise<string>
  {
    return new Promise<string>((resolve,reject)=>{
     
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (event) =>
      {
        var imgElement = new Image();
        resolve(event.target.result.toString());
      }
    })
}

  UploadPic(event:any)
  {
    console.log(event);
    if(this.useCommpression)
    {
      this.compressImage(event.target.files[0]).then(async data =>
        {
          this.imageChangedEvent = data;
        });
    }
    else{
      this.compressImage(event.target.files[0]).then(async data =>
        {
          this.imageChangedEvent = data;
        });
     
    }
    this.showImageEditor = true;
   
   
  }

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }

  onZoom(rangeValue : MatSlider){
    this.scale = rangeValue.value / this.scaleFactor;
    this.transform = {
            scale: this.scale
         };
    // if(this.value % this.scaleFactor == 0){
    //    this.scale = 1+this.value * 0.02;
    //       this.transform = {
    //       scale: this.scale
    //    };
    //  }
  }

}
