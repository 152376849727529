import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { AuthGuard } from './auth.guard';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
  {
    path: '', component:HomeComponent
  },
  {
    path: 'about', component:AboutComponent
  },
  {
    path: 'privacypolicy', component:PrivacyPolicyComponent
  },
  {
    path: 'contact', component:ContactComponent
  },
  {
    path: 'termsandconditions', component:TermsAndConditionsComponent
  },
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) , canActivate:[AuthGuard]},
  {
    path:'MCalls',loadChildren: () => import('./modules/mcall/mcall.module').then(m => m.McallModule), canActivate:[AuthGuard]},
  {
    path:'OPVisualize',loadChildren: () => import('./modules/optionchain-visualization/optionchain-visualization.module').then(m => m.OptionchainVisualizationModule) , canActivate:[AuthGuard]
  },
  { path: 'operations', loadChildren: () => import('./modules/operations/operations.module').then(m => m.OperationsModule), canActivate:[AuthGuard]  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy:QuicklinkStrategy})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
