import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(){}

  public startLoading(): void {
      this.$loading.next(true);
  }

  public stopLoading(): void {
      this.$loading.next(false);
  }
}
