import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreakPointService {

  public isDesktop = true;

    constructor(public breakpointObserver: BreakpointObserver) {
      // NOTE: Use the Breakpoint Observer to switch observe
      //       mobile/desktop changes...
      this.breakpointObserver
        .observe(['(min-width: 768px)'])
        .subscribe((state: BreakpointState) => {
          this.isDesktop = state.matches;
        });
    }

}
