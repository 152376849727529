import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import linkifyStr from 'linkifyjs/string';

@Pipe({
  name: 'linkyfy'
})
export class LinkyfyPipe implements PipeTransform {

  constructor(private _domSanitizer: DomSanitizer) {}

    /**
   * Takes a value and makes it lowercase.
   */
  transform(str: string): string {
    return str ? linkifyStr(str, {target: '_system'}) : str;
  }

}
