import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { User } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class FollowUnfollowService {

  constructor(private afs:AngularFirestore) { }

  getFollowing(followerId:string, followedId:string) {
    // Used to see if UserFoo if following UserBar
    return this.afs.doc(`following/${followerId}/${followedId}`);
  }

  follow(followerId: string, followedId: string) {
    // this.db.object(`followers/${followedId}`).update({ [followerId]: true } )
    // this.db.object(`following/${followerId}`).update({ [followedId]: true } )
    this.afs.collection('followers').doc(`${followedId}_${followerId}`).set({ followerId: followerId,followedId:followedId });
    const userDocRef = this.afs.collection('users').doc(`${followerId}`);
    userDocRef.ref.get().then(usr =>
      {
          const user = usr.data() as User;
         user.following.push(followedId);
          userDocRef.update({following:user.following });
      })
    //this.afs.collection('users').doc(`${followerId}`).set({ [followedId]: true });
  }

  unfollow(followerId: string, followedId: string) {
    this.afs.collection('followers').doc(`${followedId}_${followerId}`).delete();
    const userDocRef = this.afs.collection('users').doc(`${followerId}`);
    userDocRef.ref.get().then(usr =>
      {
          const user = usr.data() as User;
          const followingList = user.following.filter(item => item != followedId);
          userDocRef.update({following:followingList });
      })
  }
}
