
<mat-toolbar color="primary" class="headerBg">
    <mat-toolbar-row>
        <button mat-icon-button  (click)="toggleSideBar()">
            <mat-icon>menu</mat-icon>
        </button>

        <span><img class="logo" src="../../../../assets/images/logo/ProfitKhoj_logo_full_4_1_90.png" height="40px"/></span>
        <!-- <div fxFlex fxLayout="row" fxLayoutAlign="center">
            <app-market-index></app-market-index>
        </div> -->
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
            <ul fxLayout="row" fxLayoutGap="20px">
                <!-- <li>
                    <button mat-icon-button>
                        <mat-icon>settings</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-icon-button>
                        <mat-icon>help_outline</mat-icon>
                    </button>
                </li> -->
              
                    <div *ngIf="auth.user$ | async; then authenticated else guest">
                        <!-- template will replace this div -->
                    </div>
                    <ng-template #authenticated>
                        <li>
                        <button mat-button [matMenuTriggerFor]="menu">
                            <mat-icon>person_outline</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="goToEditProfile(auth.user$)">
                                <mat-icon>perm_identity</mat-icon>
                                Manage Profile
                            </button>
                            <button mat-menu-item (click)="signOut()">
                                <mat-icon>exit_to_app</mat-icon>
                                Sign out
                            </button>
                        </mat-menu>
                        </li>
                    </ng-template>
                    <ng-template #guest>
                        <li>
                            <button class="button" mat-stroked-button color="primary"  routerLink="/login">Sign In</button>
                        </li>
                    </ng-template>
                   
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<div style="padding: 1px;" *ngIf="loadingService.$loading | async"> 
    <mat-progress-bar color="primary" mode="query" ></mat-progress-bar>
</div>
