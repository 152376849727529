import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-loading-dialog',
    templateUrl: './loading-dialog.component.html'
  })
export class LoadingDialogComponent implements OnInit {

    message: any = '';
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { 
      this.message = data.message
    }
    ngOnInit(): void {
    }
}