import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PostData } from '../models/TipInfo';
import { Stat, User } from './user.model';

interface DocArray {
  key : number;
  doc : AngularFirestoreCollection<PostData>;
} 
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  prevPageNumber: number;
  lastDoc:any = undefined;
  prevPostDataCol : DocArray[] = [];
  postDataCol :  AngularFirestoreCollection<PostData>;
  constructor(public afs:AngularFirestore) { }

  getProfitLossStatement(currentPageNumber:number,userId:string): Observable<any[]>
  {
    if(this.prevPageNumber < currentPageNumber)
    {
      let prevDoc = this.prevPostDataCol.filter(data => data.key == this.prevPageNumber);
      if(prevDoc.length == 0)
      {
        this.prevPostDataCol.push({ key : this.prevPageNumber , doc: this.postDataCol});
      }
      
      this.postDataCol = this.afs.collection<PostData>('pr_PostData',ref => {
        return this.lastDoc == undefined ? (ref.where('iscalculable','==',true)
                                          .where('createdBy','==',userId)
                                          .orderBy('createdDate','desc')
                                          .limit(10)) 
              : (ref.where('iscalculable','==',true)
              .where('createdBy','==',userId)
              .orderBy('createdDate','desc')
              .startAfter(this.lastDoc)
              .limit(10))
      });
      this.prevPageNumber = currentPageNumber;
    }
    else{
      let prevDoc = this.prevPostDataCol.filter(data => data.key == currentPageNumber);
      console.log(this.prevPostDataCol.filter(data => data.key == currentPageNumber));
      this.postDataCol = prevDoc[0] == undefined ? this.afs.collection<PostData>('pr_PostData',ref => 
      {
        return ref.where('iscalculable','==',true)
        .where('createdBy','==',userId)
        .orderBy('createdDate','desc')
        .limit(10)
      }) : prevDoc[0].doc ; 
      this.prevPageNumber = currentPageNumber;
    }
   
  //  console.log(this.postDataCol.ref.path.);

    return this.postDataCol.snapshotChanges().pipe(map(
      actions => actions.map(a =>
        {
            const data = a.payload.doc.data() as PostData;
            this.lastDoc = a.payload.doc;
            // let isprofit = true;
            // let qty = 0;
            let percentGain = 0;
           

           
            let pl = 0;
            let outcome = 'Awaiting'
            if(data.buyorsell == "B")
            {
              // if(data.lotsize == 0)
              // {
              //   slgap =  data.cmp - data.sl;
              //   qty =  data.title == "Intraday" ? Math.floor(3000/slgap) : Math.floor(7000/slgap); //round of to an even number so that half qty can be derived
              //   qty = qty % 2 == 1 ? qty -1 : qty;
              // }
              // else
              // {
              //   qty = data.lotsize
              // } 
              //const halfQty = qty/2;
              if(data.issuccess == true)//target achieved
              {
                if(data.t1hit && data.t2hit)
                {
                  const avgPrice = (data.t1 + data.t2)/2
                  percentGain = (avgPrice - data.cmp)/data.cmp;
                  outcome = 'T1,T2 done';
                }
                else if(data.t1hit && data.isactive)
                {
                  percentGain = (data.t1 - data.cmp)/data.cmp;
                  outcome = 'T1 done,Awaitng T2';
                }
                else if(data.t1hit && data.slbuyhit)
                {
                  percentGain = (data.t1 - data.cmp)/data.cmp;
                  outcome = 'T1 done,SLB hit';
                }
                else if(data.t1hit && !data.isactive)
                {
                  percentGain = (data.t1 - data.cmp)/data.cmp;
                  outcome = 'T1 done';
                }
               
               
              }
              else{
                if(data.slhit)
                {
                  percentGain = (data.sl - data.cmp) /data.cmp;
                  outcome = 'SL hit';
                }
                else if(data.slbuyhit)
                {
                  percentGain = 0;
                  outcome = 'SLB hit';
                }
               
              }
            }
            else
            {
              // if(data.lotsize == 0)
              // {
              //   slgap =  data.sl - data.cmp ;
              //   qty =  data.title == "Intraday" ? Math.floor(3000/slgap) : Math.floor(7000/slgap); //round of to an even number so that half qty can be derived
              //   qty = qty % 2 == 1 ? qty -1 : qty;
              // }
              // else
              // {
              //   qty = data.lotsize 
              // } 
              // const halfQty = qty/2;
             
              if(data.issuccess == true)//target achieved
              {
                if(data.t1hit && data.t2hit)
                {
                  const avgPrice = (data.t1 + data.t2)/2
                  percentGain = (data.cmp - avgPrice)/data.cmp;
                  outcome = 'T1,T2 done';
                }
                else if(data.t1hit && data.isactive)
                {
                  percentGain = (data.cmp - data.t1)/data.cmp;
                  outcome = 'T1 done,Awaitng T2';
                }
                else if(data.t1hit && data.slbuyhit)
                {
                  percentGain = (data.cmp - data.t1)/data.cmp;
                  outcome = 'T1 done,SLB hit';
                }
                else if(data.t1hit && !data.isactive)
                {
                  percentGain = (data.cmp - data.t1)/data.cmp;
                  outcome = 'T1 done';
                }
               
               
              }
              else{
                if(data.slhit)
                {
                  percentGain = (data.cmp - data.sl)/data.cmp;
                  outcome = 'SL hit';
                }
                else if(data.slbuyhit)
                {
                  pl = 0;
                  outcome = 'SLB hit';
                }
               
              }
            }
            percentGain = +(percentGain*100).toFixed(2);
            return {percentGain,outcome,...data}
            
        })
    ));
  }

  CalculateOverAllStat()
  {
  
    const userRef = this.afs.collection('users', ref => ref.where('roles.advisor','==',true));
    userRef.get().forEach(qs => { qs.forEach(userSnap => 
      {
        const userObj = userSnap.data() as User;
        this.postDataCol = this.afs.collection<PostData>('pr_PostData',ref => {
          return  ref.where('iscalculable','==',true)
          .where('createdBy' , '==' , userObj.uid)
        });
     
   
      //  console.log(this.postDataCol.ref.path.);
        let totaltradecount  = 0;
        let winningtrade = 0;
        let losingtrade = 0;
        let activetrade = 0;
        let overallgain = 0;
        this.postDataCol.get().forEach(qs => {
          qs.forEach(ds => {
            const data = ds.data() as PostData;

            let percentGain = 0;
            if(data.isactive == true)//target achieved
              {
                activetrade = activetrade + 1
              }
            totaltradecount = totaltradecount + 1 
            if(data.buyorsell == "B")
            {
            
              if(data.issuccess == true)//target achieved
              {
                winningtrade = winningtrade + 1;
                if(data.t1hit && data.t2hit)
                {
                  const avgPrice = (data.t1 + data.t2)/2
                  percentGain = (avgPrice - data.cmp)/data.cmp;
                }
                else if(data.t1hit && data.isactive)
                {
                  percentGain = (data.t1 - data.cmp)/data.cmp;
                }
                else if(data.t1hit && data.slbuyhit)
                {
                  percentGain = (data.t1 - data.cmp)/data.cmp;
                }
                else if(data.t1hit && !data.isactive)
                {
                  percentGain = (data.t1 - data.cmp)/data.cmp;
                }
              
              
              }
              else{
                if(data.slhit)
                {
                  losingtrade = losingtrade + 1 
                  percentGain = (data.sl - data.cmp) /data.cmp;
                }
              
              }
            }
            else
            {
          
              if(data.issuccess == true)//target achieved
              {
                if(data.t1hit && data.t2hit)
                {
                  const avgPrice = (data.t1 + data.t2)/2
                  percentGain = (data.cmp - avgPrice)/data.cmp;
                }
                else if(data.t1hit && data.isactive)
                {
                  percentGain = (data.cmp - data.t1)/data.cmp;
                }
                else if(data.t1hit && data.slbuyhit)
                {
                  percentGain = (data.cmp - data.t1)/data.cmp;
                }
                else if(data.t1hit && !data.isactive)
                {
                  percentGain = (data.cmp - data.t1)/data.cmp;
                }
              
              
              }
              else{
                if(data.slhit)
                {
                  percentGain = (data.cmp - data.sl)/data.cmp;
                }
              
              }
            }
            percentGain = +(percentGain*100).toFixed(2);
            overallgain = overallgain + percentGain;
          });
        }).then(() =>{
         
              userObj.stats = <Stat>{ "totaltrade" : totaltradecount,
                                      "winningtrade" : winningtrade, 
                                      "losingtrade" : losingtrade,
                                      "activetrade" : activetrade,
                                      "overallgain": overallgain} 
              // totaltrade = totaltradecount;
              // userObj.stats.winningtrade = winningtrade;
              // userObj.stats.losingtrade = losingtrade;
              // userObj.stats.activetrade = activetrade;
              // userObj.stats.overallgain = overallgain;
              userSnap.ref.set(userObj, {merge:true});
              alert('Updated Successfully');
      
      });
    });//foreach ds
  })//foreach qs
  }

}
