import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { LoadingDialogComponent } from '../shared/components/loading-dialog/loading-dialog.component';
@Injectable({
  providedIn: 'root'
})
export class LoadingDialogService {
  constructor(public dialog: MatDialog){}

  public startLoading(msg:string): void {
     this.dialog.open(LoadingDialogComponent, {
      data: {
        message: msg
      }
    });
  }

  public stopLoading(): void {
    this.dialog.closeAll();
  }
}
