import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { User } from './user.model';

@Injectable({
    providedIn: 'root'
  })
  export class UserService {
    private userCollection :  AngularFirestoreCollection<any>;
    userId : string;
    sharedUserProfile: any;
    selecteduser:User;
    constructor(private afs: AngularFirestore,private auth:AuthService) { 
        this.userCollection = this.afs.collection('users');
        this.auth.user$.subscribe(user => 
            {
              if(user)
              {
                this.userId = user.uid;
              }
            });
    }

    CheckHandleExist(handle:string) : Observable<boolean>
    {
      console.log(handle);
      return this.afs.collection('users',ref => 
            ref.where("handle","==",handle)
            .where("uid" ,"!=",this.userId)
            ).valueChanges().pipe(map(qs => !!qs.length))
               
    }

    getUserAnalyst()
    {
      return this.afs.collection<User>('users' ,ref => ref.where('roles.advisor','==',true)).valueChanges();
    }

    getUserById(userId:string) {
      return this.afs.doc<User>(`users/${userId}`);
    }

    public saveTokenToFirestore(token) {
      if (!token) return;
    
      const devicesRef = this.afs.collection('devices')
    
      const docData = { 
        token
      }
    
      return devicesRef.doc(token).set(docData);
    }

    public setUserProperty(property)
    {
      const devicesRef = this.afs.collection('users')
      return devicesRef.doc(this.userId).set(property,{merge:true});
    }


  }