import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './app-message.component.html',
  styleUrls: ['./app-message.component.scss']
})
export class AppMessageComponent implements OnInit {
@Input() messagetype :string
@Input() message :string
  constructor() { }

  ngOnInit(): void {
  }

}
