import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { ChartComponent } from '../chart/chart.component';
import { FutureIndexOverviewComponent } from './future-index-overview/future-index-overview.component';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from 'src/app/shared/shared.module';
import { WinProbablityGaugeComponent } from './win-probablity-gauge/win-probablity-gauge.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { EODMarketAnalysisComponent } from './eodmarket-analysis/eodmarket-analysis.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EditEODMarketDataComponent } from './edit-eodmarket-data/edit-eodmarket-data.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatNativeDateModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GroupedBarChartComponent } from './grouped-bar-chart/grouped-bar-chart.component';

@NgModule({
  declarations: [DashboardComponent,
    ChartComponent,
    FutureIndexOverviewComponent,
    WinProbablityGaugeComponent,
    UserDashboardComponent,
    EODMarketAnalysisComponent,
    EditEODMarketDataComponent,
    GroupedBarChartComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FlexLayoutModule,
    MatCardModule,
    SharedModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule

  ],
  exports:[WinProbablityGaugeComponent,EODMarketAnalysisComponent]
})
export class DashboardModule { }
