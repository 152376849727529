<div *ngIf="auth.user$ | async; then authenticated else guest">
    <!-- template will replace this div -->
</div>
<ng-template #authenticated>
   
    <mat-nav-list>
       
        <h2 matSubheader>Pages</h2>
        <button mat-menu-item *ngFor="let menu of menuItem" (click)="navigate(menu)">
            <mat-icon>{{menu.icon}}</mat-icon>
            <span>{{menu.display}}</span>
          </button>
        <!-- <a mat-list-item routerLinkActive="list-item-active" (click)="navigate('/OPVisualize')">Option Chain Visualization</a> -->
       
        <mat-divider></mat-divider>
    
        <h2 matSubheader>Tools</h2>
    
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/contact">
            <mat-icon>import_contacts</mat-icon>
            Contact Us
        </a>
    
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/privacypolicy">
            <mat-icon>policy</mat-icon>
            Privacy Policy
        </a>
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/termsandconditions">
            <mat-icon>fact_check</mat-icon>
            Terms And Conditions
        </a>
    
    </mat-nav-list>
    
    
</ng-template>

<ng-template #guest>
    <mat-nav-list>
        <h2 matSubheader>Pages</h2>
    
        <a mat-list-item routerLink="/">
            <mat-icon>home</mat-icon>
           Home
        </a>
          <a mat-list-item routerLink="/about">
            <mat-icon>groups</mat-icon>
           About
          </a>
        <mat-divider></mat-divider>
    
        <h2 matSubheader>Others</h2>
    
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/contact">
            <mat-icon>import_contacts</mat-icon>
            Contact Us
        </a>
    
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/privacypolicy">
            <mat-icon>policy</mat-icon>
            Privacy Policy
        </a>
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/termsandconditions">
            <mat-icon>fact_check</mat-icon>
            Terms And Conditions
        </a>
    
    </mat-nav-list>
    
</ng-template>




