import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactInvalid = false;
  contactError;
  showContact=true;
  siteKey:string = "6LeGaccaAAAAAJwTNJCO3EQXexqWZbpRNw7p7v0-";
  nameCtrl = new FormControl('',Validators.compose([Validators.required]));
  emailCtrl = new FormControl('',Validators.compose([Validators.required,Validators.email]));
  mobileCtrl = new FormControl('',Validators.compose([ Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
  messageCtrl = new  FormControl('',Validators.compose([Validators.required]));
  recaptchaCtrl = new FormControl('',Validators.compose([Validators.required]));
  frmGrpContact = new FormGroup(
    {
      name : this.nameCtrl,
      email : this.emailCtrl,
      mobile : this.mobileCtrl,
      message : this.messageCtrl,
      recaptcha :this.recaptchaCtrl
    });
  constructor(private afs:AngularFirestore) { }

  ngOnInit(): void {
  }

  send(event)
  {
    console.log(this.frmGrpContact.valid);
    console.log(this.frmGrpContact.value);
    const contactData = this.frmGrpContact.value;
    if(this.frmGrpContact.valid)
    {
      
       this.afs.collection('contactdetails').add({name: contactData.name, email:contactData.email,mobile:contactData.mobile,message:contactData.message}).then(()=>
       {
         this.showContact = false;
       }).catch((err)=>{
        this.contactInvalid = true;
        this.contactError ="Something went wrong.Please try again after sometime"
        console.log(err);
       })
    }
    else{

        this.contactInvalid = true;
        if(contactData.recaptcha == "")
        {
          this.contactError ='Please check "I m not a Robot" checkox';
          return;
        }
        this.contactError ="Please make sure you have enter all required details."
    }
  }

}
