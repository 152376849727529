import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';

import { DashboardComponent } from './dashboard.component';
import { EditEODMarketDataComponent } from './edit-eodmarket-data/edit-eodmarket-data.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';

const routes: Routes = [{ path: '', component: DashboardComponent },
{ path: 'userdashboard',component: UserDashboardComponent , canActivate: [AuthGuard]},
{ path: 'userdashboard/:id',component: UserDashboardComponent , canActivate: [AuthGuard]},
{ path: 'editmarketdata',component: EditEODMarketDataComponent , canActivate: [AuthGuard]}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
