import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { merge } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingDialogService } from 'src/app/services/loading-dialog.service';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/shared/directives/format-datepicker';
import { PhotoCropperDialogComponent } from '../../login/photo-cropper-dialog/photo-cropper-dialog.component';

@Component({
  selector: 'app-edit-eodmarket-data',
  templateUrl: './edit-eodmarket-data.component.html',
  styleUrls: ['./edit-eodmarket-data.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class EditEODMarketDataComponent implements OnInit {
  croppedImage:any;
  showSpinner;
  task: AngularFireUploadTask;
  private userId :string;
  downloadURL: any;
  dateCtrl = new FormControl('',Validators.compose([Validators.required]));
  conclusionCtrl = new FormControl('',Validators.compose([Validators.required]));
  editEODMarketDataGrp = new FormGroup(
    {
      date : this.dateCtrl,
      conclusion : this.conclusionCtrl
    });
  constructor(public auth:AuthService,private afStorage:AngularFireStorage
    ,public dialog: MatDialog
    ,private afs:AngularFirestore
    ,private loadingDialog:LoadingDialogService) { }

  ngOnInit(): void {
  }
  OpenImageCropper()
  {
    const dialogRef = this.dialog.open(PhotoCropperDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data : {
        aspectRatio : 16/9,
        useCompression:true,
        maintainAspectRatio:false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != '')
      {
        this.croppedImage = result;
        this.GetImageUrl();
      }
     
    });
  }

  private GetImageUrl()
  {
     this.loadingDialog.startLoading("Uploading image..");
    const timeStamp = new Date().getTime();
    const baseUrl = `/images/EODMarketData`;
    console.log(baseUrl);
    const ref = this.afStorage.ref(`${baseUrl}/Eod_Summary.png`);
    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    this.task = ref.putString(this.croppedImage,'data_url');
    this.task.snapshotChanges().pipe(
      finalize(() => 
      {
          ref.getDownloadURL().toPromise().then(urlOrg => 
            {
                this.downloadURL = urlOrg;
                this.loadingDialog.stopLoading();
            }).catch(err => console.log(err));
          })).subscribe();
  }

  UpdateEODData(event)
  {
    this.loadingDialog.startLoading('Updating record.Please wait..');
    const formData = this.editEODMarketDataGrp.value
    const eodDataRef: AngularFirestoreDocument<any> = this.afs.doc(`eod_market_data/MD`);
    const data ={
      imgUrl: this.downloadURL,
      analysisDate: formData.date,
      conclusion:formData.conclusion
    }
    console.log(data);
    // eodDataRef.ref.get().then(userSnap => 
    //   {
    //     //userObj.picture = this.profilePic == null ?  userObj.picture : this.profilePic
        eodDataRef.set(data, { merge: true }).then(()=>
        {
          this.loadingDialog.stopLoading();
        }).catch(err => {
          this.loadingDialog.stopLoading();
          alert(err);
        })
       
     // });
  }

}
