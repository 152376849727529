import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from './user.model';
import * as firebase from 'firebase';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { FollowUnfollowService } from './follow-unfollow.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;
  userDisplayName: string = '';
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private followService:FollowUnfollowService
    ) 
    { 
     
      this.user$ = this.afAuth.authState.pipe(
        switchMap(user => {
            // Logged in
          if (user) {
            if(user.emailVerified)
            {
              return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
            }
            else
            {
              return of(null);
            }
          
          } else {
            // Logged out
            return of(null);
          }
        })
      )
    }

    async googleSignin() {
      const provider = new firebase.default.auth.GoogleAuthProvider();
      const credential = await this.afAuth.signInWithPopup(provider);
      return this.updateUserData(credential.user);
    }
  
    private updateUserData(user) {
      // Sets user data to firestore on login
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      userRef.ref.get().then(userSnap => 
        {
          const userObj = userSnap.data() as User;
          userObj.uid =  user.uid;
          userObj.email = user.email;
          if(userObj.displayName == null && this.userDisplayName != null) 
          {
            userObj.displayName = this.userDisplayName;
          } 
          console.log(userObj.following);
          userObj.roles.subscriber = true;
          return userRef.set(userObj, { merge: true }).then(() =>
          {
            if(userObj.following?.length == 0)
            {
              console.log('called')
              const userRefTOFollow = this.afs.collection('users',ref => ref.where('email','in',['rinturajster@gmail.com','hemanthimthani@gmail.com']));
              userRefTOFollow.get().forEach(qs => 
                {
                qs.forEach(qds => 
                  {
                    const userToFollow = qds.data() as User;
                    this.followService.follow(userObj.uid,userToFollow.uid);
                  });
                  
                  });
              }
          })
        });
  
    }

    async signUp(credentials) {
      return this.afAuth.createUserWithEmailAndPassword(credentials.email,credentials.password).then((result)=> 
      {
        this.userDisplayName = credentials.displayName;
        this.updateUserData(result.user);
        result.user.sendEmailVerification();
          
      });
    }

    async signInWithEmail(credentials) {
      return this.afAuth.signInWithEmailAndPassword(credentials.email,
         credentials.password).then((result)=> 
         {
             this.updateUserData(result.user);
             return result.user;
         });
    }
  
    async signOut() {
      await this.afAuth.signOut();
      this.router.navigate(['/']);
    }

    async resetPassword(email: string) {
      return this.afAuth.sendPasswordResetEmail(email)
        .then(() => console.log("email sent"))
        .catch((error) => console.log(error))
    }
  
}
