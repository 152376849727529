<div class="container">
    <div class="contactHeader">
        <span>Need Help Or Have any feedback? Connect with us!</span>
    </div>
   
    
        <form [formGroup]="frmGrpContact" (ngSubmit)="send($event)" *ngIf="showContact">
            <div fxLayout="column"  fxLayoutAlign="center" fxLayoutGap="20px">
            <mat-error *ngIf="contactInvalid">
              {{contactError}}
            </mat-error>
         
            <mat-form-field appearance="fill" class="txtBox">
                <mat-label>Name</mat-label>
              <input matInput [formControl]="nameCtrl" required maxlength="50"/>
              <mat-error>
                Please provide your name.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="txtBox">
                <mat-label>Email</mat-label>
              <input matInput [formControl]="emailCtrl"  maxlength="150" required />
              <mat-error  *ngIf="emailCtrl.hasError('required')">
                Please provide your email address.
              </mat-error>
              <mat-error *ngIf="emailCtrl.hasError('email')">Please enter a valid email address</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="txtBox">
                <mat-label>Mobile</mat-label>
              <input matInput [formControl]="mobileCtrl"  maxlength="10"/>
              <mat-error  *ngIf="mobileCtrl.hasError('pattern')">
                Please enter numeric value.
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Message</mat-label>
                <textarea matInput #message  [formControl]="messageCtrl" cdkTextareaAutosize rows="4" maxlength="2000"></textarea>
            </mat-form-field>
            <ngx-recaptcha2 #captchaElem
                [siteKey]="siteKey"
                [formControl]="recaptchaCtrl">
            </ngx-recaptcha2>
           
                <button mat-raised-button color="accent" class="btnContact">Send</button>
            
        </div>
          </form>
          <div *ngIf="!showContact" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="top" fxLayoutAlign="center start" fxLayoutGap="32px">
   
            <div fxFlex="0 1 calc(80%-32px)">
                <div class="info">
                    Thank you for contacting us. We will get back to you as soon as possible.
                </div>
               
            </div>
        </div>
</div>
