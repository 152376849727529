import { Component, OnInit, Input, ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { FutureIndex } from 'src/app/modules/dashboard/future-index-overview/FutureIndex';
import { GoogleDriveProviderService } from 'src/app/services/google-drive-provider.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-future-index-overview',
  templateUrl: './future-index-overview.component.html',
  styleUrls: ['./future-index-overview.component.scss']
})
export class FutureIndexOverviewComponent implements OnInit {
  hostElement; // Native element hosting the SVG container
  svg; // Top level SVG element
  g;
  
  @Input() data: FutureIndex[];
  constructor(private elemRef: ElementRef,private googleDrivePrvider: GoogleDriveProviderService,private loadingService:LoadingService) { 
   this.hostElement = this.elemRef.nativeElement;
  }

  ngOnInit(): void {
    this.generateFutureIndexStackedBar();
  }

  onResize() {
    this.generateFutureIndexStackedBar();
  }


  generateFutureIndexStackedBar()
  {
    this.removeExistingChartFromParent();

  console.clear();
  
//  console.log(width);
// console.log(height);

this.loadingService.startLoading();
this.googleDrivePrvider.getSheetData().subscribe(data =>
          
      {

        var jsonData : FutureIndex[] =[];
        data.filter(d=> d.catergory == "NET POSITION"|| d.catergory == "CHANGE IN NET POSITION").forEach((d) => {
          jsonData.push({'Period': d.period,'Stakeholder': d.stakeholder,'Category':d.catergory,'NetOI':d.netoi});
        });

        console.log(data);
        var groupData = d3.nest<FutureIndex,any>()
        .key(function(d) { return d.Category+d.Period })
        .rollup(function(d){
          
                var d2  =  {Category: d[0].Category, Period: d[0].Period} 
                d.forEach(function(d){
                  d2[d.Stakeholder] = d.NetOI
                })
                //console.log("rollup d", d, d2);
                return d2;
              }) 
        .entries(jsonData)
        .map(function(d){ return d.value; });

        var categorywiseValue = d3.nest<FutureIndex,any>()
        .key(function(d) { return d.Category+d.Period })
        .rollup(function(d){
          
          var d2  =  {Category: d[0].Category, Period: d[0].Period} 
         //var allVal : number[] = [];
         var negativeVal = 0;
          d.forEach(function(d){
            if(+d.NetOI < 0)
            {
              negativeVal += +d.NetOI
            }
            //allVal.push(+d.NetOI);
          })
          d2["Min"] = negativeVal;
          //console.log("rollup d", d, d2);
          return d2;
        }) 
        .entries(jsonData)
        .map(function(d){ return d.value; });

            
  //.sort(([, a], [, b]) => d3.ascending(a, b))



        var  margin = {top: 40, right: 40, bottom: 30, left: 60};
        let viewBoxHeight = groupData.length * 33 + margin.top + margin.bottom
        let viewBoxWidth = 960 ;
       // console.log(this.hostElement);
        this.svg = d3.select(this.hostElement).append('svg')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight);
   
      var width = viewBoxWidth - margin.left - margin.right;
      var height = viewBoxHeight - margin.top - margin.bottom;
    this.g = this.svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

     
        
    var y0 = d3.scaleBand()
.rangeRound([0, height])
    //.paddingInner(0.2);
//     var netOI = jsonData.map(a=> +a.NetOI);
// console.log(d3.max(netOI));
var y1 = d3.scaleBand()
  //  .padding(10);
    var x = d3.scaleLinear()
    .rangeRound([0, width])
    // .rangeRound([0, x0.bandwidth()])
    // .padding(0.3);


    


var x1 = d3.scaleBand()
  
var color  = d3.scaleOrdinal(d3.schemeTableau10);

var stack = d3.stack()
    .offset(d3.stackOffsetExpand); 
     
   
          y0.domain(jsonData.map(function(d) { return d.Period; }))
        
      y1.domain(jsonData.map(function(d) { return d.Category; }))
      .rangeRound([0,y0.bandwidth()])
      //.padding(0.1);
     
        // .rangeRound([margin.top, height - margin.bottom])
         

     

        color.domain(jsonData.map(function(d) { return d.Stakeholder; }))
          var keys = color.domain();

         
      
      var keyGrouping = [];          
     
      groupData.forEach(function(d) {
			var x0_positive = 0;
			var x0_negative = 0;

			var currentGrp: any = keys.map(function(key) {
				if (d[key] >= 0) {
					return {key: key, x1: x0_positive, x0: x0_positive += +d[key],data : d };
				} else if (d[key] < 0) {
					return {key: key, x0: x0_negative, x1: x0_negative += +d[key], data: d };
				}
      })
      keyGrouping.push(currentGrp);
    });
      
      keyGrouping = this.flatten(keyGrouping);
      console.log("groupdata", keyGrouping);
      var x0_min = d3.min(keyGrouping, function(d) { return +d.x0 - 0.1 });
      var x0_max = d3.max(keyGrouping, function(d) { return +d.x0 + 0.1 });
      var x1_min = d3.min(keyGrouping, function(d) { return +d.x1 - 0.1 });
      var x1_max = d3.max(keyGrouping, function(d) { return +d.x1 + 0.1 });

      var x_min = x0_min < x1_min ? x0_min : x1_min;
      var x_max = x0_max > x1_max ? x0_max : x1_max;
      var x_min_margin = x_min + (x_min * 20/100);
      var x_max_margin = x_max + (x_max * 20/100);
      //console.log(y_min_margin);
      //console.log(y_max);
  
          x.domain([x_min_margin,x_max_margin]);

      var finalData = [];
      keys.forEach((k) =>
      {
        finalData.push(keyGrouping.filter(x => x.key == k));
      });
    

      var stackData = stack.keys(keys)(groupData);
      //console.log("stackData", stackData);
      //y.domain([0, d3.max(data, function(d) { return d.Value; })]).nice();

      //console.log("keys", keys)
      
      var serie = this.g.selectAll(".serie")
        .data(finalData)
        .enter().append("g")
          .attr("class", "serie")
         //.attr("fill", (d:any) => z(d.key))
         .attr("fill", function(d) {  return color(d[0].key) as string; });
          
          
      
          //console.log("bandwidth" +x1.bandwidth());
          var animationDuration = 400;
          var delayBetweenBarAnimation = 10;
          var barDelay = function (d, i) { return i * delayBetweenBarAnimation; };
          var stackedBarX = function (d) { return x(d.x0) - Math.abs(x(d.x1) - x(d.x0)); };
          var barWidth = function (d) { return Math.abs(x(d.x1) - x(d.x0)); };

          var transitionStackedBars = function (selection) {
            selection.transition()
                .duration(animationDuration)
                .delay(barDelay)
                .attr("x", stackedBarX)
                .attr("width", barWidth);
        };

      serie.selectAll("rect")
        .data(function(d) {  return d })
        .enter().append("rect")
          .attr("class", "serie-rect")
          .attr("transform", function(d) { return "translate(0," +  y0(d.data.Period.toString()) +")"; })
          .attr("y", function(d) { return y1(d.data.Category.toString()); })
          .attr("x", width)
          .attr("width", 0 )
          .attr("height", 30)
          .call(transitionStackedBars)
          .append("title")
          .text((d)=> {return d.data[d.key].toString()});
          
         // .on("click", function(d, i){ console.log("serie-rect click d", i, d); })
          
          serie.selectAll("serie-rect")
          .data(categorywiseValue)
          .enter().append("text")
          .text((d) =>  {return d.Category.toString()})
          .attr("transform", function(d) { return "translate(0," +  y0(d.Period.toString()) +")"; })
          .attr("y", function(d) { return y1(d.Category.toString())+20; })
          //.attr("width","100")
          .attr("x", function(d) { return x(d.Min) - ((this.getComputedTextLength()*0.6)) })
          .style("font-size","8")
          .attr("stroke-width", 1)
          .attr("fill", "#777B7E");
      
      this.g.append("g")
          .attr("class", "axis")
          .attr("stroke-width", 1)
          .call(d3.axisTop(x))
          .style("font-size","11");

      this.g.append("g")
          .attr("class", "axis")
          //.attr("transform", (d) => "translate("+x_min_margin+","+ y1(d.data.Category.toString()) + y1.bandwidth() / 2+")")
          .attr("stroke-width", 1)
          .style("font-size",'10')
          .call(d3.axisLeft(y0).ticks(null, "s"))
        .append("text")
          .attr("y", -30)
          .attr("x", 0)
          .attr("dy", "0.35em")
          .attr("fill", "#777B7E")
          .style("font-size","14")
          .style("padding","10")
          .attr("font-weight", "bold")
          .attr("text-anchor", "start")
          .text("Future Index");

    //     serie.selectAll(".label")  		
	  // .data(function(d) { return d[0] })
	  // .enter()
	  // .append("text")
    // .attr("class","label")
    // .attr("transform", function(d) { return "translate(" + y0(d.data.Period.toString()) + ",0)"; })
	  // .attr("y", (function(d) { return y1(d.data.Category.toString()); }  ))
    // .attr("x", function(d) { return x(x_min_margin);  })
    // .attr("width", y1.bandwidth())
	  // .attr("dy", ".11em")
    // .text(function(d) { return d.data.Category.toString() })
    //.call(this.wrap, x1.bandwidth());
    //.attr("transform", function(d) { return "rotate(-45)"; })   	  
    //  serie.selectAll(".label") 
    //  .call(this.wrap, y1.bandwidth());
    // .attr("transform", function(d) { return "rotate(-45)"; });
      
        var legend = this.svg.selectAll(".legend")
        .data(color.domain())
        .enter().append("g")
        .attr("class", "legend");
        //.attr("transform", function(d, i) { return "translate(0," + i * 20 + ")"; });
  
      legend.append("rect")
        .attr("x", 900)
        .attr("y", function(d, i) { return i * 25 + 200 })
        .attr("width", 18)
        .attr("height", 18)
        .style("fill", color );
  
      legend.append("text")
        .attr("x", 925)
        .attr("y", function(d, i) { return i * 25 + 209; })
        .attr("dy", ".35em")
        .style("text-anchor", "start")
        .text(function(d, i) { return keys[i]; });


        this.loadingService.stopLoading();
      });

  }

  private removeExistingChartFromParent() {
    // !!!!Caution!!!
    // Make sure not to do;
    //     d3.select('svg').remove();
    // That will clear all other SVG elements in the DOM
    d3.select( this.hostElement).select('svg').remove();
}
flatten(arr) {
  return [].concat(...arr);
}

wrap(text, width) {
  text.each(function() {
    var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
      }
    }
  });
}
}
