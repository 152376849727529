<div *ngIf="showSpinner" fxLayout="column" fxLayoutAlign="space-between center">
    <mat-spinner diameter="50"></mat-spinner>
</div>
<div fxLayout="column" class="topdiv">
    <h2>Edit EOD Market Data</h2>
    <div fxLayout="row" fxLayoutAlign="start">
        <div fxFlex="50%" fxFlex.xs="100%">
            <form [formGroup]="editEODMarketDataGrp" fxLayout="column" fxLayoutGap="20px">
                <div class="container">
                    <img #profileimg class="avatar" [src]="croppedImage">
                </div>
                <a color="primary" style="align-self: start;" mat-button (click)="OpenImageCropper()" target="_blank">Add/Change Picture</a>
      
            <mat-form-field appearance="outline">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" [formControl]="dateCtrl">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Conclusion</mat-label>
                <textarea matInput  [maxLength]="500" [formControl]="conclusionCtrl" cdkTextareaAutosize></textarea>
                <mat-error *ngIf="conclusionCtrl.hasError('required')">Please enter your profile name</mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="UpdateEODData($event)" style="width:150px;">Update EOD Data</button>
        </form>
        </div>
          
    </div>
</div>
