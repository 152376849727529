<mat-card *ngIf="eodData$ | async as eodData">
    <mat-card-header>
      <mat-card-title><span class="titledash">EOD Market Analysis for {{eodData.analysisDate.toDate() | date}}</span></mat-card-title>
      <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
      <button  mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions" *ngIf="loggedInUser.roles.admin">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['./editmarketdata']">
          <mat-icon>create</mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item>
          <mat-icon>share</mat-icon>
          <span>Share</span>
        </button>
      </mat-menu>
    </mat-card-header>

         
      
    <mat-card-content>
      <img  mat-card-image [src]="eodData.imgUrl" alt="EOD Market Analysis">
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button>
        <mat-icon>share</mat-icon>
        <span>Share</span>

      </button>
    </mat-card-actions>
  </mat-card>
