import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { PostData } from 'src/app/models/TipInfo';
import { DashboardService } from 'src/app/services/dashboard.service';
import { User } from 'src/app/services/user.model';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit,AfterViewInit {
  pageSize=10;
  pageLength=155;
  displayedColumns: string[] = ['companycode', 'cmp', 'sl', 't1','t2','createdDate','percentGain'];
  dataSource : MatTableDataSource<PostData>;
  loggedInUserId:string;
  loggedInUser : any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public dashboardService:DashboardService,
    private afs:AngularFirestore,
    private actRoute: ActivatedRoute) { 
    this.loggedInUserId = actRoute.snapshot.params.id;
    //console.log(this.loggedInUserId);
   
    
  }
  ngOnInit() {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${this.loggedInUserId}`);
    userRef.get().forEach(qs => {
      this.loggedInUser = qs.data() as User;
      console.log(this.loggedInUser);
    }).then(()=>{
      this.dashboardService.getProfitLossStatement(1,this.loggedInUserId).subscribe(data => 
        {
          this.dataSource = new MatTableDataSource<PostData>(data);
      this.pageLength = this.loggedInUser.stats.totaltrade;
        });
    });
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
  }

  pageChanged(event)
  {
    this.dashboardService.getProfitLossStatement(event.pageIndex+1,this.loggedInUserId).subscribe(data => 
      {
        this.dataSource = new MatTableDataSource<PostData>(data);
      })
  }

  getColorCode(data : PostData)
  {
      if(data.issuccess == true)//target achieved
      {
        if(data.t1hit || data.t2hit)
        {
          return 'green'
        }
        
      }
      else{
        if(data.slhit)
        {
          return 'red'
        }
        else{
          return 'orange'
        }
      }
  }
}